.entityQueueHome {
  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 30px;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(1, auto);
    margin-bottom: 30px;

    .Item0 { grid-area: 1 / 1 / 2 / 25; }
    .Item1 { grid-area: 2 / 1 / 3 / 25; }
    .Item2 { grid-area: 3 / 1 / 4 / 25; }
    .Item3 { grid-area: 4 / 1 / 5 / 25; }
    .Item4 { grid-area: 6 / 1 / 7 / 25; }
    .Item5 { grid-area: 7 / 1 / 8 / 25; }
    .Item6 { grid-area: 8 / 1 / 9 / 25; }
    .Item7 { grid-area: 9 / 1 / 10 / 25; }
    .Item8 { grid-area: 10 / 1 / 11 / 25; }
    .Item9 { grid-area: 11 / 1 / 12 / 25; }
    .Item10 { grid-area: 12 / 1 / 13 / 25; }
    .Item11 { grid-area: 13 / 1 / 14 / 25; }
    .Item12 { grid-area: 14 / 1 / 15 / 25; }
    .Item13 { grid-area: 15 / 1 / 16 / 25; }
    .Item14 { grid-area: 16 / 1 / 17 / 25; }
    .Item15 { grid-area: 17 / 1 / 18 / 25; }
    .Item16 { grid-area: 18 / 1 / 19 / 25; }
    .Item17 { grid-area: 19 / 1 / 20 / 25; }
    .Item18 { grid-area: 20 / 1 / 21 / 25; }
    .Item19 { grid-area: 21 / 1 / 22 / 25; }
    .Item20 { grid-area: 22 / 1 / 23 / 25; }
    .Item21 { grid-area: 23 / 1 / 24 / 25; }
    .Item22 { grid-area: 24 / 1 / 25 / 25; }
  }

  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: $gridGutterWidthSm;
      grid-row-gap: $margin40;
      grid-template-columns: repeat(24, 1fr);
      margin-bottom: $margin40;

      .Item0 { grid-area: 1 / 1 / 2 / 25; }
      .Item1 { display: none }
      .Item2 { grid-area: 2 / 1 / 3 / 13; }
      .Item3 { grid-area: 2 / 13 / 3 / 25; }
      .Item4 { display: none }
      .Item5 { grid-area: 3 / 1 / 4 / 13; }
      .Item6 { grid-area: 3 / 13 / 4 / 25; }
      .Item7 { grid-area: 4 / 1 / 4 / 13; }
      .Item8 { grid-area: 4 / 13 / 4 / 25; }
      .Item9 { grid-area: 5 / 1 / 6 / 13; }
      .Item10 { grid-area: 5 / 13 / 6 / 25; }
      .Item11 { grid-area: 6 / 1 / 6 / 13; }
      .Item12 { grid-area: 6 / 13 / 7 / 25; }
      .Item13 { grid-area: 7 / 1 / 8 / 13; }
      .Item14 { grid-area: 7 / 13 / 8 / 25; }
      .Item15 { grid-area: 8 / 1 / 9 / 13; }
      .Item16 { grid-area: 8 / 13 / 9 / 25; }
      .Item17 { grid-area: 9 / 1 / 10 / 13; }
      .Item18 { grid-area: 9 / 13 / 10 / 25; }
      .Item19 { grid-area: 10 / 1 / 11 / 13; }
      .Item20 { grid-area: 10 / 13 / 11 / 25; }
      .Item21 { grid-area: 11 / 1 / 12 / 13; }
      .Item22 { grid-area: 11 / 13 / 12 / 25; }
    }
  }

  @media (min-width: $lgBreakpoint) {
    .Grid0 {
      grid-column-gap: $gridGutterWidthXl;
      grid-row-gap: 50px;
      margin-bottom: 50px;

      .Item0 { grid-area: 1 / 1 / 2 / 25; }
      .Item1 { display: none }
      .Item2 { grid-area: 2 / 1 / 3 / 9; }
      .Item3 { grid-area: 2 / 9 / 3 / 17; }
      .Item4 { display: none }
      .Item5 { grid-area: 2 / 17 / 3 / 25; }
      .Item6 { grid-area: 3 / 1 / 4 / 9; }
      .Item7 { grid-area: 3 / 9 / 4 / 17; }
      .Item8 { grid-area: 3 / 17 / 4 / 25; }
      .Item9 { grid-area: 4 / 1 / 4 / 9; }
      .Item10 { grid-area: 4 / 9 / 4 / 17; }
      .Item11 { grid-area: 4 / 17 / 4 / 25; }
      .Item12 { grid-area: 4 / 19 / 5 / 25; }
      .Item13 { grid-area: 5 / 1 / 6 / 9; }
      .Item14 { grid-area: 5 / 9 / 6 / 17; }
      .Item15 { grid-area: 5 / 17 / 6 / 25; }
      .Item16 { grid-area: 6 / 1 / 7 / 9; }
      .Item17 { grid-area: 6 / 9 / 7 / 17; }
      .Item18 { grid-area: 6 / 17 / 7 / 25; }
      .Item19 { grid-area: 7 / 1 / 8 / 9; }
      .Item20 { grid-area: 7 / 9 / 8 / 17; }
      .Item21 { grid-area: 7 / 17 / 8 / 25; }
    }
  }
}
