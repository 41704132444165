.Wrapper {
  composes: ImageParagraph from 'typography.legacy.css';
  display: inline-block;
  margin: 0;
  padding: 8px 0;
  text-align: left;
  width: 100%;

  em {
    font-style: italic;
  }

  a {
    border-bottom: 1px solid;
    color: $blackB;
    padding: 1px;
  }

  :global(.catchquestion) {
    font-weight: 900;
    text-transform: uppercase;
  }

  @media print {
    text-align: left;
  }
}

.CaptionMarketingPage {
  @mixin InfoParagraphBlack;
}

.Credits {
  composes: ImageParagraphMeta from 'typography.legacy.css';
  margin-top: $margin4;
  position: relative;
  top: -1px;
}
