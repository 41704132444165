.Wrapper {
  margin-bottom: $margin56;
}

.Title {
  composes: H3 from 'typography.legacy.css';
  margin-bottom: $margin8;
  word-break: break-word;

  @media (min-width: $xlBreakpoint) {
    margin-bottom: $margin16;
  }
}

.Keyword {
  composes: Meta3Black from 'typography.legacy.css';
  background-color: $greyE;
  display: inline-block;
  margin-bottom: $margin8;
  margin-right: $margin8;
  padding: 8px;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;

  @media (min-width: $smBreakpoint) {
    &:hover {
      background-color: $blackB;
      color: $white;
    }
  }
}
