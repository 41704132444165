.Wrapper {
  margin-bottom: $margin16;
  margin-top: 36px;
}

.Title {
  composes: H2 from 'typography.legacy.css';
  margin-bottom: 8px;
}

.Description {
  composes: FormLabelBig from 'typography.legacy.css'; 
  margin-bottom: 36px;
}
