.Wrapper {
  @mixin backgroundPatternGray $greyB;
}

.Container {
  padding: 18px 0 28px;

  @media (min-width: $smBreakpoint) {
    padding: 24px 0 40px;
  }

  @media (min-width: $mdBreakpoint) {
    padding: 46px 0 50px;
  }

  @media (min-width: $xlBreakpoint) {
    padding: 40px 0 48px;
  }
}

.Content {
  @media (min-width: $mdBreakpoint) {
    float: left;
    width: 320px;
  }

  @media (min-width: $xlBreakpoint) {
    width: 450px;
  }
}

.Headline {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.3rem;
  line-height: 3.3rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2rem;
  }

  @media (min-width: $mdBreakpoint) {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 2rem;
  }
}

.Subhead {
  color: $blackB;
  font-family: $fontGeorgia;
  font-size: 2.5rem;
  line-height: 3rem;

  @media (min-width: $smBreakpoint) {
    font-size: 3.7rem;
    line-height: 4.2rem;
  }

  @media (min-width: $mdBreakpoint) {
    font-size: 3.9rem;
    line-height: 4.6rem;
  }
}

.IconWrapper {
  composes: Clearfix from 'helpers.legacy.css';
  list-style: none;
  margin-top: 15px;

  @media (min-width: $smBreakpoint) {
    margin-top: 20px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-top: 15px;
  }

  li:last-of-type > a {
    margin-right: 0;
  }
}
