.Logo {
  fill: $white;
  max-height: 100%;
  max-width: 100%;
}

.LogoWhite {
  fill: $white;
}

.LogoBlue {
  fill: $blackB;
}

.LogoRed {
  fill: $primaryC;
}
