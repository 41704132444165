.Wrapper {
  align-items: center;
  background-color: $greyE;
  display: flex;
  flex-direction: row;
  margin-bottom: $margin16;
  padding: 24px 12px;
  text-decoration: none;
  
  @media (min-width: $smBreakpoint) {
    flex-direction: column;
    height: calc(100% - 16px);
  }

  @media (min-width: $mdBreakpoint) {
    height: calc(100% - 24px);
    margin-bottom: $margin24;
  }

  @media (min-width: $xlBreakpoint) {
    padding: 24px 56px;
  }

}

.Icon {
  height: auto;
  margin-right: $margin16;
  max-width: 64px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin8;
    margin-right: 0;
    max-height: 50px;
    max-width: none;
    width: auto;
  }
}

.Text {
  composes: RichTextWrapper from '../Paragraphs/components/TextParagraph/styles.legacy.css';
  composes: InfoParagraphBlack from 'typography.legacy.css';
  text-align: left;

  @media (min-width: $smBreakpoint) {
    text-align: center;
    width: 100%;
  }
}
