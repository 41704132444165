.Wrapper {
  margin: 20px 0;
  text-align: left;

  @media (min-width: $smBreakpoint) {
    margin-top: $margin32;
  }

  @media (min-width: $mdBreakpoint) {
    margin-top: $margin40;
  }
}
