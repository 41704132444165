.Wrapper {
  background-color: $white;
  height: 100%;
  left: 0;
  overflow: scroll;
  position: absolute;
  top: 0;
  width: 100%;
}

.MenuHeader {
  background-color: $greyE;
}

.MenuHeaderContent {
  height: 44px;
  padding-bottom: 12px;
  padding-top: 12px;
  position: relative;
}

.CloseButton {
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 0;
  z-index: $zIndexOverlay;
}

.CloseIcon {
  color: $blackB;
  font-size: 2rem;
  opacity: 0.8;
  transition: opacity 0.3s easy-in;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.UserInformationWrapper {
  background-color: $blackB;
  padding-bottom: 16px;
  padding-top: 16px;

  @media (min-width: $smBreakpoint) {
    padding-bottom: 24px;
    padding-top: 24;
  }

  @media (min-width: $xlBreakpoint) {
    padding-bottom: 32px;
    padding-top: 32px;
  }
}

.UserName {
  composes: H3White from 'typography.legacy.css';
}

.UserCredentials {
  composes: Lead2White from 'typography.legacy.css';
  margin-top: $margin8;
}

.MenuWrapper {
  @media (min-width: $smBreakpoint) {
    margin-top: $margin24;
  }
}

.MenuItem {
  line-height: 5rem;
  list-style: none;
  margin-bottom: 12px;
  position: relative;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: $margin24;
  }
}
