.ArticleTitle {
  position: relative;
  z-index: $zIndexMedium;
}

.TimeToRead {
  margin-bottom: $margin16;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin16;
  }
}

.Title {
  composes: H1 from 'typography.legacy.css';
  margin-bottom: $margin16;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin32;
  }

  &.HasTimeToRead {
    margin-bottom: $margin8;
  }
}

.ShortTitleWrapper {
  align-items: center;
  display: flex;
  margin-bottom: $margin8;
  white-space: nowrap;
  
  @media (min-width: $mdBreakpoint) {
    margin-bottom: $margin8;
  }
}

.ShortTitle {
  composes: ShortTitleA from 'typography.legacy.css';
  display: inline;
  margin-right: $margin8;
}

.ShortTitleNA{
  composes: ShortTitleBNativeArticle from 'typography.legacy.css';
  float: left;
  padding: 4px 8px;
  width: fit-content;

}

.ShortTitleAdvertorial {
  composes: ShortTitleBAdvertorial from 'typography.legacy.css';
  float: left;
  padding: 4px 8px;
  width: fit-content;
}

.UtilityBarWrapper {
  @mixin hideforPrint;
  background-color: $white;
  border-top: 1px solid $greyC;
  bottom: 0;
  height: 70px;
  left: 0;
  position: fixed;
  right: 0;
  z-index: $zIndexMedium;

  @media (min-width: $smBreakpoint) {
    background-color: $transparent;
    border-top: 0;
    height: auto;
    margin-bottom: $margin24;
    padding-top: 16px;
    position: relative;
  }
}

.ArticleBody {
  :global(#app) {
    padding-bottom: 70px;

    @media (min-width: $smBreakpoint) {
      padding-bottom: 0;
    }
  }
}
