.GridRow {
  margin-bottom: 30px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin40;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 50px;
  }
}

.Divider {
  border-top: 1px solid $greyB;

  @media (min-width: $xlBreakpoint) {
    border-top: 0;

    &::before {
      background-color: $greyB;
      content: '';
      height: 1px;
      position: absolute;
      width: 218px;
    }
  }
}

.AdContainerFull {
  overflow: hidden;
  width: 100%;
}

.AdContainer {
  composes: AdContainer from 'helpers.legacy.css';
  align-items: center;
  background: $greyA;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  position: relative;

  @media (min-width: $smBreakpoint) {
    height: 330px;
    min-height: 0;
  }
}
