.Wrapper {
  composes: Unselectable from 'helpers.legacy.css';
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  text-align: center;
  @media (min-width: $smBreakpoint) {
    max-width: none;
  }
}

.PageLink {
  color: $blackA;
  cursor: pointer;
  font-family: $fontRoboto;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 5px 10px;
}

.PagerPlaceholder {
  display: inline;
}

.PrevButton,
.NextButton {
  padding: 3px 0;
}

.Disabled {
  cursor: not-allowed;
  opacity: 0.2;
}

.Icon {
  color: $blackB;
  font-size: 3rem;
  margin-top: -10px;
  position: relative;
  top: 8px;
}

.ActiveItem {
  background-color: $blackB;
  color: $white;
}

@media (min-width: $smBreakpoint) {
  .Wrapper {
    display: block;
  }

  .PageLink {
    font-size: 1.5rem;
    padding: 10px 20px;
  }

  .PrevButton,
  .NextButton {
    padding: 10px 20px;
  }
}
