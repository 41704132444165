.LinkWrapper {
  display: flex;
  justify-content: center;
}

.Logo {
  display: grid;
  height: 32px;
  width: 80px;
}
