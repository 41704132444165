.ShortTitle {
  composes: H5 from 'typography.legacy.css';
  margin-bottom: $margin4;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    font-size: 1.3rem;
    margin-bottom: 7px;
  }
}

.Wrapper {
  background-color: $greyE;
  height: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 100%;
}

.Container {
  composes: Container from '@grid.legacy.css';
  
  display: flex;
  height: 100%;
  justify-content: space-between;
  max-width: 100%;
  padding: 12px 0 30px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    padding: 24px 0 18px;
  }

  @media (min-width: $mdBreakpoint) {
    padding: 24px 0 26px;
  }
}

.ContentWrapper {
  flex: 2;
}

.Lead {
  composes: Lead1 from 'typography.legacy.css';
  margin-bottom: 21px;
}

.Headline {
  composes: H2 from 'typography.legacy.css';
  margin-bottom: 15px;
}

.LinkButton {
  composes: Button from 'typography.legacy.css';
  background-color: $blackA;
  color: $white;
  cursor: pointer;
  display: block;
  padding: 13px 0;
  text-align: center;
  transition: background-color 0.3s ease-in-out;
  width: 100%;

  &:hover {
    background-color: $blackB;
  }

  @media (min-width: $smBreakpoint) {
    display: inline-block;
    padding: 13px 20px;
    width: auto;
  }

  @media (min-width: $lgBreakpoint) {
    display: inline-block;
    padding: 20px;
  }
}

.ImageWrapper {
  display: none;
  flex: 1;

  @media (min-width: $mdBreakpoint) {
    display: block;
  }
}

.TeaserImage {
  display: none;
  position: absolute;
  width: auto;

  @media (min-width: $mdBreakpoint) {
    bottom: 0;
    display: block;
    max-height: 100%;
  }
}
