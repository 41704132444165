.Wrapper {
  composes: Clearfix from 'helpers.legacy.css';
  background-color: $white;
  display: block;
  line-height: 0;
  position: relative;

  @media (min-width: $smBreakpoint) {
    display: flex;
  }
}

.Left,
.Right {
  @media (min-width: $smBreakpoint) {
    width: 50%;
  }
}

.Left {
  line-height: 0;
  position: relative;
}

.Right {
  border-bottom: 1px solid $greyA;
  padding-bottom: 20px;

  @media (min-width: $smBreakpoint) {
    margin-left: $gridGutterWidthXl;
    min-height: 234px;
  }
}

.TeaserTitleWrapper {
  margin-bottom: $margin8;
  padding-top: 6px;

  @media (min-width: $smBreakpoint) {
    margin-top: -6px;
    padding-top: 0;
  }
}

.Title {
  composes: H2 from 'typography.legacy.css';
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.Lead {
  composes: Lead2 from 'typography.legacy.css';
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.BottomLine {
  composes: Meta1 from 'typography.legacy.css';
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.Icon {
  bottom: 19px;
  position: absolute;
  right: 23px;
  z-index: 1;

  @media (min-width: $mdBreakpoint) {
    bottom: 20px;
    right: 20px;
  }
}

.Image {
  height: auto;
  width: 100%;
}

.Badge {
  bottom: 19px;
  left: 0;
  position: absolute;

  @media (min-width: $smBreakpoint) {
    bottom: 25px;
  }

  @media (min-width: $xlBreakpoint) {
    bottom: 35px;
  }
}

.ShortTitle {
  composes: ShortTitleBGreyA from 'typography.legacy.css';
  margin-bottom: $margin4;
  margin-top: $margin8;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin8;
    margin-top: 0;
  }
}

.ShortTitleNA{
  composes: ShortTitleDNativeArticle from 'typography.legacy.css';
}

.ShortTitleAdvertorial {
  composes: ShortTitleDAdvertorial from 'typography.legacy.css';
}
