@define-mixin InfoParagraphBlack {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.5rem;
  line-height: 2.4rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

@define-mixin H2 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 2.1rem;
  font-weight: 700;
  line-height: 2.5rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
}

@define-mixin H2Center {
  @mixin H2;
  text-align: center;
}

@define-mixin Lead1 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.8rem;
  line-height: 2.5rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.1rem;
    line-height: 3.1rem;
  }
}

@define-mixin ButtonBase {
  cursor: pointer;
  font-family: $fontRoboto;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

@define-mixin ButtonBig {
  @mixin ButtonBase;
  font-size: 1.8rem;
  height: 54px;
  line-height: 2.2rem;
  padding: 0 24px;
}

@define-mixin ButtonSmall {
  @mixin ButtonBase;
  font-size: 1.4rem;
  height: 34px;
  line-height: 1.8rem;
  padding: 0 16px;
}

@define-mixin ButtonPrimary {
  background-color: $primaryA;
  border: none;
  border-radius: 4px;
  color: $white;

  &:hover {
    background-color: $primaryC;
  }
}

@define-mixin ButtonSecondary {
  background-color: $white;
  border: 1px solid $primaryA;
  border-radius: 4px;
  color: $primaryA;

  &:hover {
    border: 1px solid $primaryC;
    color: $primaryC;
  }
}

@define-mixin ButtonTertiary {
  border: none;
  color: $primaryA;

  &:hover {
    color: $primaryC;
  }
}

@define-mixin ButtonPrimaryBig {
  @mixin ButtonPrimary;
  @mixin ButtonBig;
}

@define-mixin ButtonPrimarySmall {
  @mixin ButtonPrimary;
  @mixin ButtonSmall;
}

@define-mixin ButtonSecondaryBig {
  @mixin ButtonSecondary;
  @mixin ButtonBig;
}

@define-mixin ButtonSecondarySmall {
  @mixin ButtonSecondary;
  @mixin ButtonSmall;
}

@define-mixin ButtonTertiaryBig {
  @mixin ButtonTertiary;
  @mixin ButtonBig;
}

@define-mixin ButtonTertiarySmall {
  @mixin ButtonTertiary;
  @mixin ButtonSmall;
}

@define-mixin AdCaption {
  color: $greyC;
  font-family: $fontHelvetica;
  font-size: 1.1rem;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}

@define-mixin FormLabelABlack {
  color: $blackA;
  font-family: $fontRoboto;
  font-size: 1.6rem;
  line-height: 2.2rem;
}

@define-mixin FormLabelATextSecondary {
  @mixin FormLabelABlack;
  color: $greyA;
}

@define-mixin FormLabelBBlack {
  color: $blackA;
  font-family: $fontRoboto;
  font-size: 1.2rem;
  letter-spacing: 0.3px;
  line-height: 1.6rem;
}

@define-mixin FormLabelBError {
  @mixin FormLabelBBlack;
  color: $error;
}

@define-mixin FormLabelBTextSecondary {
  @mixin FormLabelBBlack;
  color: $greyA;
}

@define-mixin RaschIconFiles {
  display: inline;
  font-family: $fontRasch;
  margin-right: 1px;
  vertical-align: top;
}

@define-mixin Info5 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1rem;
  line-height: 1.4rem;
}

@define-mixin RichTextLink {
  border-bottom: 1px solid $primaryA;
  color: $primaryA;
  padding: 1px;

  &:hover{
    border-bottom-color: $primaryC;
    color: $primaryC;
  }

  &:not([href*='pme.ch']) {
    &:not([href^='#']) {
      &:not([href^='/']) {
        &:not([href^='mailto:']) {
          &:not([href^='tel:']) {
            &:not([class*='teaser']) {
              &:not([class*='utility-button']) {
                &:not([class*='Icon']) {
                  &::after {
                    @mixin RaschIconFiles;
                    content: '\e921';
                    margin-left: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media print {
    border-bottom: none;
    color: $blackB;

    &::after {
      display: none !important; /* stylelint-disable-line */
    }
  }
}

