.Wrapper {
  height: 35px;
  margin-top: 12px;

  @media (min-width: $smBreakpoint) {
    margin-top: 30px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-top: 36px;
  }
}

.FilterWrapper {
  float: right;
}

.Label {
  color: $blackA;
  font-family: $fontRoboto;
  font-size: 1rem;
  margin-right: 11px;

  @media (min-width: $smBreakpoint) {
    font-size: 1.2rem;
  }
}

.FilterItem {
  background-color: $greyB;
  border: none;
  color: $white;
  cursor: pointer;
  font-family: $fontRoboto;
  font-size: 1rem;
  margin-left: 1rem;
  opacity: 1;
  padding: 0.8rem 1.4rem;

  &:first-of-type {
    margin-left: 0;
  }

  @media (min-width: $smBreakpoint) {
    font-size: 1.2rem;
  }
}

.ActiveFilterItem {
  background: $greyA;
}
