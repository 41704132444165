.teaser3x2 {
  /* Mobile */
  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    margin-bottom: 30px;
  }

  /* Tablet */
  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: $gridGutterWidthSm;
      grid-row-gap: $margin40;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: $margin40;
    }
  }

  @media (min-width: $xlBreakpoint) {
    .Grid0 {
      grid-column-gap: $gridGutterWidthXl;
      grid-row-gap: 50px;
      margin-bottom: 50px;
    }
  }
}

