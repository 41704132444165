.Wrapper {
  display: none;

  @media (min-width: $smBreakpoint) {
    display: block;
  }
}

.ContentWrapper {
  composes: Container from '@grid.legacy.css';
  margin-bottom: -16px;
}

.LinkWrapper {
  display: flex;
  height: 32px;
  justify-content: center;
  position: relative;

  &::after {
    background: linear-gradient(90deg, rgb(255 255 255 / 0%), $white); /* There is a Safari bug so we cannot use transparent here. */
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    z-index: $zIndex1;
  }
}

.LinkList {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0 auto;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 16px;
  position: relative;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  z-index: 1;
}

.ListItem {
  overflow: visible;
  scroll-snap-align: start;
}

.HeaderWrapper {
  border-bottom: 1px solid $greyB;
  display: block;
  margin-bottom: $margin12;
  margin-top: $margin32;
  padding-bottom: 16px;
}

.Link {
  composes: InfoD from 'typography.legacy.css';
  padding: 0 12px;

  &:hover {
    color: $greyA;
  }
}
