.Container {
  position: relative;
}

.AdWrapper {
  position: absolute;
  right: -107px;
  width: 0;
}

.Ad {
  background-color: $transparent;
  display: inline-block;
  position: fixed;
  top: 55px;

  /* should be over piano overlay */
  z-index: calc($zIndexPianoOverlay + 25);
}

.Sticky {
  position: fixed;
  top: 55px;
}
