.Reply {
  composes: InfoH2 from 'typography.legacy.css';
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: $margin16 0 $margin32;
  outline: 0;
  padding: 0;
  width: 100%;
}

.Icon {
  font-size: 2rem;
  margin-bottom: -5px;
  margin-left: 5px;
  padding-left: 6px;
}

.IconChevronUp,
.IconChevronUpActive {
  transition: transform 300ms linear;
}

.IconChevronUpActive {
  transform: rotate(180deg);
}
