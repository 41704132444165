.WrapperTimeline {
  position: relative;
}

.LeftCol {
  display: none;
  @media (min-width: $smBreakpoint) {
    display: inline-block;
  }
}

.Left {
  position: relative;
  text-align: right;
  width: 100%;

  &::after {
    background-color: $blackB;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 13px;
    position: absolute;
    right: calc(($gridGutterWidthMd + 7.5px) * -1);
    top: 0;
    width: 13px;

    @media (min-width: $smBreakpoint) {
      right: calc(($gridGutterWidthMd + 15px) * -1);
      top: 22px;
    }

    @media (min-width: $xlBreakpoint) {
      right: calc(($gridGutterWidthXl + 15px) * -1);
    }
  }
}

.TimelineDateTimeline {
  composes: InfoParagraphBlack from 'typography.legacy.css';
  position: relative;
  top: -8px;

  @media (min-width: $smBreakpoint) {
    top: 15px;
  }
}

.Right {
  border-left: 1px solid $blackB;
  margin-bottom: -40px;
  margin-left: 10px;
  padding-bottom: 21px;
  padding-top: 15px;

  @media (min-width: $smBreakpoint) {
    padding-bottom: 29px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-left: $margin8;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: -54px;
    padding-bottom: 34px;
  }
}

.Wrapper {
  display: inline-block;
  margin-left: 17px;

  @media (min-width: $smBreakpoint) {
    margin-left: 28px;
  }
}

.TeaserTitle {
  composes: H3 from 'typography.legacy.css';
}

.TimelineDate {
  composes: HiddenSmUp from '@grid.legacy.css';
  composes: InfoParagraphBlack from 'typography.legacy.css';
  display: inline-block;
  position: relative;

  @media (min-width: $smBreakpoint) {
    top: 0;
  }

  &::before {
    background-color: $blackB;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 11px;
    left: -23px;
    position: absolute;
    top: 6px;
    width: 11px;

    @media (min-width: $smBreakpoint) {
      display: none;
    }
  }
}
