.Wrapper {
  overflow: hidden;
  position: relative;

  em {
    font-style: italic;
  }

  &::before {
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.GoldenBackground::before {
  background-color: $greyE;
  height: 150px;
  z-index: -1;

  @media (min-width: $smBreakpoint) {
    height: 190px;
  }

  @media (min-width: $mdBreakpoint) {
    height: 200px;
  }
}

.GoldenBackgroundHero::before {
  background-color: $greyE;
  height: 320px;
  z-index: -1;

  @media (min-width: $smBreakpoint) {
    height: 450px;
  }

  @media (min-width: $mdBreakpoint) {
    height: 500px;
  }

  @media (min-width: $xlBreakpoint) {
    height: 660px;
  }
}

.IsSeatChange::before {
  background-color: $greyE;
  height: 129px;

  @media (min-width: $smBreakpoint) {
    height: 208px;
  }
}

.ImageWrapper {
  margin-bottom: 12px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 20px;
  }
}

.Caption {
  composes: ImageParagraph from 'typography.legacy.css';
  display: inline-block;
  margin-bottom: 20px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 30px;
  }

  :global(.catchquestion) {
    font-family: $fontRoboto;
    font-weight: 700;
    text-transform: uppercase;
  }

  @media print {
    text-align: center;
  }
}

.Credit {
  composes: ImageParagraphMeta from 'typography.legacy.css';
  display: block;
  margin-top: 0;

  @media (min-width: $smBreakpoint) {
    margin-top: $margin4;
  }

  @media print {
    text-align: center;
  }
}

.Gray {
  color: $blackB;
}
