.Wrapper {
  background: color($error shade(20%) a(30%));
  border-radius: 5px;
  color: $white;
  font-family: $fontRoboto;
  font-size: 2rem;
  margin: 3rem auto;
  padding: 3rem;
  text-align: center;
}
