.Wrapper {
  position: relative;
}

.Button {
  color: $greyA;
  cursor: pointer;
  display: inline-block;
  font-size: 2rem;
  height: 20px;
  line-height: 2rem;
  outline: none;
  position: relative;
  text-align: center;
  transition: background-color 0.3s ease-in-out;
  width: 20px;

  @media (min-width: $smBreakpoint) {
    &:hover {
      color: $blackB;
    }
  }
}

.ButtonText {
  inset: 0;
  position: absolute;

  &:hover {
    color: $blackB;
  }

  &::after {
    content: '\e91c';
    display: block;
    font-family: $fontRasch;
    text-align: center;
  }
}

.ButtonOpen {
  line-height: 1.8rem;

  &::after {
    border-bottom: 13px solid $blackB;
    border-left: 11px solid transparent;
    border-radius: 4px;
    border-right: 11px solid transparent;
    bottom: -15px;
    content: '';
    height: 0;
    left: -1px;
    margin: auto;
    position: absolute;
    width: 0;
  }

  .ButtonText {
    &::after {
      content: '\2715';
      display: block;
    }
  }
}

.TooltipWrapper {
  left: -58px;
  position: relative;
  top: -4px;
  white-space: normal;
  width: 100%;
}

.Tooltip {
  background-color: $blackB;
  border-radius: 4px;
  max-width: 480px;
  padding: 10px;
  position: relative;
  width: 100%;
  z-index: $zIndex1;
}

.Content {
  composes: Meta3White from 'typography.legacy.css';
}

.Link {
  composes: Meta3Bold from 'typography.legacy.css';
  display: inline;
}

.BrandreportWrapper {
  height: 20px;
}
