.Wrapper {
  position: relative;
}

.PagerWrapper {
  margin-top: $margin40;
  text-align: center;
}

/* Used in LandingPageDefault & LandingPageSpecial */
.Title {
  composes: ChannelTitle from 'typography.legacy.css';
  margin-bottom: 0;
  margin-left: 0;
  position: relative;
}
