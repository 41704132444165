.Wrapper {
  list-style: none;
  max-width: 280px;

  @media (min-width: $smBreakpoint) {
    display: flex;
    justify-content: space-between;
    max-width: 233px;
  }

  li:last-of-type > a {
    margin-right: 0;
  }
}

.Link {
  border-radius: 50%;
  float: left;
  margin-right: 11px;
  position: relative;
  transition: opacity 0.3s ease-in;

  &:hover {
    opacity: 0.7;
  }
}

.Content {
  background-color: $white;
  border-radius: 50%;
  height: 38px;
  position: relative;
  text-align: center;
  width: 38px;
}

.Icon svg {
  fill: $blackB;
  height: 26px;
  margin-top: 7px;
  width: 26px;
}

/* Style for Origin Navigation */
.NavigationWrapper {
  display: flex;
  justify-content: space-between;
  max-width: 232px;

  @media (min-width: $mdBreakpoint) {
    max-width: 156px;
  }

  .Content {
    height: 27px;
    width: 27px;
  }

  .Icon svg {
    color: $blackB;
    fill: $blackB;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  .Link {
    margin-right: $margin24;

    &:hover {
      opacity: 1;

      .Icon svg {
        color: $greyA;
        fill: $greyA;
      }
    }

    @media (min-width: $mdBreakpoint) {
      margin-right: 5px;
    }
  }
}
