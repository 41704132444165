@define-mixin sectionDefaultMargin {
  margin-bottom: 30px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin40;
  }
}

@define-mixin SectionDefaultMarginTop {
  margin-top: 30px;

  @media (min-width: $smBreakpoint) {
    margin-top: $margin40;
  }
}
