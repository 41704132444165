.Clearfix {
  *zoom: 1;

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

.Unselectable {
  user-select: none;
}

.TextEllipsis {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ASPECT RATIOS */
.AspectRatio {
  position: relative;

  &::before {
    content: ' ';
    display: block;
  }
}

.AdContainer {
  height: 100%;
  text-align: left;
  text-transform: uppercase;

  :global(.gmadhead) {
    line-height: 1.5;
    position: relative;
    text-align: left;
  }
}

.AdWrapper {
  display: inline-block;
  left: 50%;
  position: relative;
  transform: translateX(-50%);

  @media print {
    display: none;
  }
}

.AdWrapperInner {
  margin: 30px 0;

  @media (min-width: $smBreakpoint) {
    margin: 0;
  }
}

/* .OrangeShortTitle composed in
  ArticleTitle/styles.css
  TeaserSecondaryDefault/styles.css
  LongReadHeader/styles.css
*/
.OrangeShortTitle {
  background-color: $primaryA;
  color: $white;
  font-size: 1.5rem;
  letter-spacing: 1.5px;
  padding: 1px 4px;
}

.TextButton {
  /* Corrects inability to style clickable `input` types in iOS */
  appearance: none;

  background: transparent;
  border: none;

  /* inherit font & color from ancestor */
  color: $inherit;
  font: inherit;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

/* This class is used to pull an element outside the grid on mobile VP */
.PullOutXs {
  @media (max-width: $xsBreakpointTo) {
    margin-left: -$outerGapXs;
    margin-right: -$outerGapXs;
    width: calc(100% + $outerGapXs * 2);
  }
}
