.teaserStageDefault {
  /* Mobile */

  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);

    .Item {
      margin-bottom: 30px;
    }
    
    .Item0 { grid-area: 1 / 1 / 2 / 2; }
    .Item1 { grid-area: 2 / 1 / 3 / 2; }
    .Item2 { grid-area: 3 / 1 / 4 / 2; }
    .Item3 { grid-area: 4 / 1 / 5 / 2; }
  }

  /* Tablet */
  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: $gridGutterWidthSm;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;

      .Item {
        margin-bottom: $margin40;
      }

      .Item0 { grid-area: 1 / 1 / 2 / 2; }
      .Item1 { grid-area: 1 / 2 / 2 / 3; }
      .Item2 { grid-area: 1 / 3 / 2 / 4; }
      .Item3 { grid-area: 1 / 4 / 2 / 5; }
    }

    /* Desktop */
    @media (min-width: $xlBreakpoint) {
      .Grid0 {
        grid-column-gap: $gridGutterWidthXl;

        .Item {
          margin-bottom: 50px;
        }
      }
    }
  }
}
