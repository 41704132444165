.Form {
  position: relative;
  top: -28px;

  @media (min-width: $smBreakpoint) {
    top: 0;
  }
}

.InputWrapper {
  margin-bottom: 9px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 0;
  }
}

.SuccessTick {
  background-color: $blackB;
  border-radius: 50%;
  height: 36px;
  margin-bottom: 12px;
  margin-top: $margin32;
  position: relative;
  width: 36px;

  &::after {
    color: $white;
    content: '\ea10';
    font-family: $fontRasch;
    font-size: 2rem;
    left: 9px;
    position: absolute;
    top: 3px;
  }

  @media (min-width: $smBreakpoint) {
    height: 48px;
    margin-right: $margin16;
    margin-top: 0;
    width: 48px;

    &::after {
      font-size: 2.8rem;
      left: 10px;
      top: 9px;
    }
  }
}

.SuccessSubmission {
  composes: H4White from 'typography.legacy.css';

  @media (min-width: $smBreakpoint) {
    margin-bottom: 30px;
    width: calc(100% - 64px);
  }
}

.SuccessWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (min-width: $smBreakpoint) {
    flex-direction: row;
  }

  @media (min-width: $xlBreakpoint) {
    width: 885px;
  }
}

.ButtonWrapper {
  flex: auto;
  padding: 0 3px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    padding-left: 0 12px;
    top: 2px;
  }
}

.EmailWrapper {
  & > label {
    color: $blackA;
  }

  & > input {
    /*
     * .DescriptionBig from 'typography.legacy.css'
     *
     * Can't compose from 'typography.legacy.css' here.
     * Also we couldn't change the styles of InputField directly,
     * because it would impact on all forms in the publication
     * which is not expected in rebrush scope.
    **/
    color: $greyA;
    font-family: $fontRoboto;
    font-size: 1.5rem;
    height: 54px;
    line-height: 2.4rem;
    padding: 0 12px;

    @media (min-width: $smBreakpoint) {
      font-size: 2rem;
      line-height: 4rem;
    }
  }

  & > input:hover {
    border-color: $blackA;
  }

  & > span {
    /* ParagraphErrorLight text style */
    color: $white;
    font-family: $fontRoboto;
    font-size: 1.2rem;
  }

  @media (min-width: $smBreakpoint) {
    position: relative;
    top: -8px;
  }
}
