.OuterWrapper {
  height: 100%;
}

.Wrapper {
  border-bottom: 1px solid $greyA;
  display: block;
  height: 100%;
  letter-spacing: 0;
  line-height: 0;
  padding-bottom: 27px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    min-height: 265px;
  }

  @media (min-width: $mdBreakpoint) {
    min-height: 220px;
  }

  @media (min-width: $xlBreakpoint) {
    min-height: 198px;
  }
}

.ContentWrapper {
  width: 100%;
}

.TeaserTitle {
  composes: H4 from 'typography.legacy.css';
  margin-bottom: $margin8;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.Lead {
  composes: Lead2 from 'typography.legacy.css';
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.BottomLine  {
  composes: Meta1 from 'typography.legacy.css';
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ShortTitle {
  composes: ShortTitleBGreyA from 'typography.legacy.css';
  margin-bottom: $margin4;
  margin-top: $margin8;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ShortTitleAdvertorial {
  composes: ShortTitleD from 'typography.legacy.css';
  background-color: $decoB;
  border-radius: 4px; 
  padding: 0 4px;
  width: fit-content;
}

.ShortTitleNA {
  composes: ShortTitleAdvertorial;
  background-color: $decoA;
}
