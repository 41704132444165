.Wrapper {
  @mixin backgroundPatternDefault $white;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-left: 13px;
  padding-top: 10px;

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 30px;
  }
}

.ContentWrapper {
  height: 80px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    height: calc(25.5vw - 15px);
  }
  @media (min-width: $mdBreakpoint) {
    height: 171px;
  }

  @media (min-width: $xlBreakpoint) {
    height: 187px;
  }
}

.Title {
  color: $blackB;
  display: inline-block;
  font-size: 1.9rem;
  letter-spacing: 0.4px;
  line-height: 2.3rem;
  max-height: 50px;
  overflow: hidden;
  word-break: break-word;

  @media (min-width: $smBreakpointTo) {
    font-size: 2.2rem;
    line-height: 2.6rem;
    max-height: 80px;
  }
}

.ChildWrapper {
  position: relative;
}

.LexicalEntry {
  bottom: 7px;
  display: block;
  font-family: $fontRoboto;
  font-size: 1.4rem;
  letter-spacing: 1px;
  position: absolute;
}

.ArrowWrap {
  border-radius: 50%;
  bottom: 10px;
  color: $white;
  font-size: 1.7rem;
  height: 25px;
  position: absolute;
  right: 13px;
  width: 25px;
}

.Arrow {
  left: 4px;
  position: absolute;
  top: 4px;
}
