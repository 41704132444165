.OuterWrapper {
  height: 100%;
}

.Wrapper {
  height: 100%;
  line-height: 0;
  position: relative;

  &::after,
  &::before {
    bottom: 0;
    content: '';
    display: block;
    height: 45%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: calc($zIndexMedium - 1);
  }
}

.Wrapper::after {
  background: $gradientBlack;
}

.WrapperWithSponsor::before {
  background: $gradientWhite;
  height: 100%;
  z-index: calc($zIndexMedium - 2);

  @media (min-width: $smBreakpoint) {
    background: $gradientShineWhite;
  }

  @media (min-width: $xlBreakpoint) {
    background: linear-gradient(
      22.2deg,
      rgb(255 255 255 / 0%) 0%,
      $white 100%
    );
  }
}

.TeaserTitleWrapper {
  margin-bottom: 0;
  max-width: 100%;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 0;
    max-width: 300px;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: 0;
    max-width: 400px;
  }
}

.InnerWrapper {
  position: relative;
  width: 100%;
}

.Link {
  display: flex;
}

.Image {
  height: auto;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    left: 0;
    position: absolute;
    top: 0;
  }
}

.ImageWrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.TeaserText {
  bottom: 16px;
  left: 0;
  padding: 8px 12px;
  position: absolute;
  text-align: left;
  width: 100%;
  z-index: $zIndexMedium;

  @media (min-width: $smBreakpoint) {
    padding: 0 $gridGutterWidthSm;
  }

  @media (min-width: $mdBreakpoint) {
    padding: 0 $gridGutterWidthMd;
  }

  @media (min-width: $xlBreakpoint) {
    padding: 0 $gridGutterWidthXl;
  }
}

.ShortTitleWrapper {
  height: 22px;
  margin-bottom: $margin8;
  width: 126px;
}

.TeaserTitle {
  composes: H4White from 'typography.legacy.css';
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.AuthorWrapper {
  composes: Lead2White from 'typography.legacy.css';
  max-width: 100%;
  padding-top: 8px;
  @media (min-width: $smBreakpoint) {
    max-width: 300px;
  }

  @media (min-width: $xlBreakpoint) {
    max-width: 400px;
  }
}

/* this selector is also unsed in TeaserSIamgeTile, TeaserXSImageTile, TeaserLImageTile */
.SponsorImageWrapper {
  display: flex;
  height: 44px;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 118px;
  z-index: $zIndexMedium;

  picture img {
    height: 44px;
    width: auto;
  }

  @media (min-width: $smBreakpoint) {
    height: 49px;
    width: 130px;

    picture img {
      height: 49px;
    }
  }
  @media (min-width: $lgBreakpoint) {
    height: 42px;
    width: 111px;

    picture img {
      height: 42px;
    }
  }
}

.DedicatedPageLink {
  composes: Meta3White from 'typography.legacy.css';
  align-items: center;
  display: flex;
  padding-top: 8px;
}

.ArrowWrap {
  margin-left: 10px;
}

.Badge {
  bottom: 17px;
  left: -12px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    left: -18px;
  }

  @media (min-width: $mdBreakpoint) {
    left: -20px;
  }

  @media (min-width: $xlBreakpoint) {
    left: -24px;
  }
}
