.InnerWrapper {
  align-items: center;
  background: $greyE;
  display: flex;
  flex-direction: column;
  margin-bottom: $margin24;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 0;
  }
}

.Text {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin: 0 $margin16 $margin24 $margin16;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    font-size: 1.6rem;
    line-height: 2.6rem;
    max-width: 583px;
  }

  @media (min-width: $xlBreakpoint) {
    max-width: 760px;
  }
}

.Icon {
  animation: BellAnimation 500ms forwards;
  animation-delay: 500ms;
  color: $blackB;
  font-size: 8rem;
  line-height: 8rem;
  margin: $margin24 auto $margin8 auto;
  transform-origin: 50% 25%;

  @media (min-width: $smBreakpoint) {
    margin: $margin48 auto $margin24 auto;
  }
}

.Wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: $margin56;

  @media (min-width: $smBreakpoint) {
    align-items: center;
    background: $greyE;
    margin-bottom: $margin40;
    padding-bottom: 48px;
  }
}

.Link {
  display: block;
  height: auto;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    width: auto;
  }
}

.Button {
  composes: Button from 'form.legacy.css';
  box-shadow: 0 2px 18px -8px $blackB;
  display: inline-block;
  font-family: $fontRoboto;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.8rem;
  margin: 0;
  padding: 14px 12px;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    font-size: 2rem;
    line-height: 2.3rem;
    margin: 0 auto 0 0;
    padding: 19px 15px;
  }

  @media (min-width: $mdBreakpoint) {
    font-size: 2.2rem;
    line-height: 2.5rem;
    padding: 25px 21px;
  }
}

@mixin BellAnimation;
