.SkeletonWrapper {
  border-bottom: 1px solid $white;
  display: flex;
  padding: 10px 0 5px;
}

.SkeletonLeft {
  background-color: $white;
  height: 30px;
  margin-bottom: 5px;
  margin-right: 15px;
  width: 30px;
}

.SkeletonRight {
  width: 100%;
}

.SkeletonContent {
  background-color: $white;
  height: 20px;
  margin-bottom: 5px;
  width: 100%;
}
