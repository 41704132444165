.Title {
  composes: H3 from 'typography.legacy.css';
}

.Link {
  display: block;
  width: fit-content
}

.Link:hover {
  color: $blackA;
}

.LinkIndicator {
  display: inline-block;
  margin-left: 3px;
}

.Link:hover .LinkIndicator
{
  transform: translateX(3px);
  transition-duration: 200ms
}
