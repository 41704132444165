.Wrapper {
  composes: Container from '@grid.legacy.css';
  padding: 16px 0;

  @media (min-width: $smBreakpoint) {
    padding: 32px 0;
  }
}

.Title {
  composes: H2 from 'typography.legacy.css';
  margin-bottom: $margin8;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: $margin16;
  }
}

.Content {
  composes: RichTextWrapper from '../TextParagraph/styles.legacy.css';
  composes: InfoB from 'typography.legacy.css';
  list-style-position: inside;
  margin-bottom: $margin16;
  overflow: hidden;
  word-break: break-word;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin24;
  }
}

.ImageBox {
  margin-bottom: $margin16;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 0;
  }
}

.Footer {
  composes: RichTextWrapper from '../TextParagraph/styles.legacy.css';
  composes: InfoBBlack from 'typography.legacy.css';
}

.Even {
  background-color: $greyE;
}

.Odd {
  .ImageBox {
    order: -1;

    @media (min-width: $smBreakpoint) {
      order: initial;
    }
  }
}

.ListicleItemWrapper {
  composes: Row from '@grid.legacy.css';
}

.ListicleItemInnerWrapper {
  composes: ListicleItemWrapper;
}
