.Wrapper {
  display: block;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
}

.Label {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;
}

.Banner {
  padding: 10px 0;
}

