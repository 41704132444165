.Title {
  color: $blackA;
  font-family: $fontRoboto;
  font-size: 2.3rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.GroupTitle {
  color: $blackA;
  font-family: $fontRoboto;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.Link {
  color: $blackB;
  display: block;
  font-family: $fontRoboto;
  font-size: 1.6rem;
  line-height: 1.5;

  &:visited {
    color: $blackB;
  }
}
