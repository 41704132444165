.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    flex-direction: row;
  }
}

.LinkButton {
  composes: Button from 'typography.legacy.css';
  background-color: $greyB;
  border: 0;
  color: $white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center;
  padding: 12px 16px;
  text-align: center;
  transition: background-color 0.3s ease-in-out;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    height: 60px;
    min-width: 220px;
    width: initial;
  }

  @media (min-width: $lgBreakpoint) {
    height: 70px;
    min-width: 264px;
  }
}

.NextButton {
  composes: LinkButton;
  background-color: $blackB;

  &:hover {
    background-color: $blackB;
  }
}

.PrevButton {
  composes: LinkButton;
  background-color: $greyB;
  margin-bottom: $margin8;

  &:hover {
    background-color: $greyC;
  }

  @media (min-width: $smBreakpoint) {
    margin-bottom: 0;
    margin-right: 20px;
  }
}
