.OuterWrapper {
  height: 100%;
}

.Wrapper {
  border-bottom: 1px solid $greyA;
  display: block;
  letter-spacing: 0;
  padding-bottom: 19px;

  @media (min-width: $smBreakpoint) {
    min-height: 91px;
    padding-bottom: 27px;
  }

  @media (min-width: $xlBreakpoint) {
    min-height: 77px;
  }
}

.ContentWrapper {
  width: 100%;
}

.TeaserTitle {
  composes: H4 from 'typography.legacy.css';
  margin-bottom: 3px;
  overflow-wrap: break-word;
  word-wrap: break-word;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 7px;
  }
}

.BottomLine {
  composes: Meta1 from 'typography.legacy.css';
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
}
