.Title {
  composes: ChannelTitle from 'typography.legacy.css';
  margin-bottom: 0;
  margin-left: 0;
  padding-bottom: 12px;
  padding-top: 25px;
  position: relative;
  z-index: $zIndexMedium;

  &::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: $smBreakpoint) {
    margin-bottom: -1px;
    padding-top: 30px;
  }
}
