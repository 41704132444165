strong {
  font-weight: bold;
}

p {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: $bodyFontSize;
  margin: 0;
  padding: 0;
}

/* typography styles */

.H1 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.9rem;

  @media (min-width: $smBreakpoint) {
    font-size: 4rem;
    line-height: 4.8rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 5rem;
    line-height: 5.5rem;
  }
}

.H1White {
  composes: H1;
  color: $white;
}

.H2 {
  @mixin H2;
}

/* not in styleguide */
.H2Center {
  @mixin H2Center;
}

.H2White {
  composes: H2;
  color: $white;
}

.H3 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 2.3rem;

  @media (min-width: $xlBreakpoint) {
    font-size: 2.4rem;
    line-height: 2.6rem;
  }
}

.H3White {
  composes: H3;
  color: $white;
}

.H4 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;

  @media (min-width: $xlBreakpoint) {
    font-size: 1.9rem;
    line-height: 2.3rem;
  }
}

.H4White {
  composes: H4;
  color: $white;
}

.H5 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.4rem;
  text-transform: uppercase;
}

.H5White {
  composes: H5;
  color: $white;
}

.H5Light {
  composes: H5;
  color: $greyA;
  font-family: $fontRoboto;
  line-height: 1.9rem;
}

.H5LightInactive {
  composes: H5Light;
  color: $greyE;
}

.ImageParagraph {
  color: $greyB;
  font-family: $fontRoboto;
  font-size: 1.4rem;
  line-height: 2rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.ImageParagraphBlack {
  composes: ImageParagraph;
  color: $greyB;
}

.ImageParagraphMeta {
  color: $greyB;
  font-family: $fontRoboto;
  font-size: 1rem;
  line-height: 1.7rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }
}

/* not in styleguide */
.ImageParagraphMetaBold {
  composes: ImageParagraphMeta;
  font-family: $fontRoboto;
  font-weight: 700;
}

.ImageParagraphMetaBlack {
  composes: ImageParagraphMeta;
  color: $greyB;
}

.Lead1 {
  @mixin Lead1;
}

.Lead1Grey {
  composes: Lead1;
  color: $greyC;
}

.Lead1Center {
  @mixin Lead1;
  text-align: center;
}

.Lead2 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.4rem;
  line-height: 2rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}

.Lead2White {
  composes: Lead2;
  color: $white;
}

.Lead2Grey {
  composes: Lead2;
  color: $greyA;
}

.Lead2Center {
  composes: Lead2;
  text-align: center;
}

.Lead2Black {
  composes: Lead2;
  font-family: $fontRoboto;
  font-weight: 700;
}

.Lead2BlackWhite {
  composes: Lead2Black;
  color: $white;
}

.Lead2BlackGrey {
  composes: Lead2Black;
  color: $greyA;
}

.AuthorDateBlack {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.7rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.7rem;
    line-height: 2.5rem;
  }
}

.AuthorDateRegular {
  composes: AuthorDateBlack;
  font-family: $fontRoboto;
}

.Initial {
  color: $greyB;
  font-family: $fontRoboto;
  font-size: 10.4rem;
  font-weight: 700;
  line-height: 12rem;
}

.Paragraph {
  color: $blackB;
  font-family: $fontGeorgia;
  font-size: 1.7rem;
  line-height: 2.5rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.9rem;
    line-height: 3.2rem;
  }
}

.ParagraphBold {
  composes: Paragraph;
  font-family: $fontGeorgia;
  font-weight: 700;
}

.Subtitle {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.3rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.1rem;
    line-height: 2.5rem;
  }
}

.SubtitleRed {
  composes: Subtitle;
}

.QuoteMain {
  color: $blackB;
  font-family: $fontGeorgia;
  font-size: 2.1rem;
  line-height: 2.9rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.7rem;
    line-height: 3.7rem;
  }
}

.QuoteName {
  color: $greyB;
  font-family: $fontRoboto;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.6rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
}

.InfoH1 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.5rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.3rem;
    line-height: 3rem;
  }
}

.InfoH2Black {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.7rem;
    line-height: 2.6rem;
  }
}

.InfoH2Grey {
  composes: InfoH2Black;
  color: $greyA;
}

.InfoParagraphBlack {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.5rem;
  line-height: 2.4rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.InfoParagraphBlackCenter {
  composes: InfoParagraphBlack;
  text-align: center;
}

/* not in styleguide */
.InfoParagraphBlackUppercase {
  composes: InfoParagraphBlack;
  text-transform: uppercase;
}

.InfoParagraphGrey {
  composes: InfoParagraphBlack;
  color: $greyC;
}

.InfoParagraphWhite {
  composes: InfoParagraphBlack;
  color: $white;
}

.InfoParagraphErrorDark {
  composes: InfoParagraphBlack;
  color: $errorA;
}

.InfoParagraphErrorLight {
  composes: InfoParagraphBlack;
  color: $errorB;
}

.InfoParagraphLink {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.5rem;
  line-height: 2.4rem;
  text-decoration: underline;

  @media (min-width: $smBreakpoint) {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.ContentBoxBig {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 2.1rem;
  font-weight: 700;
  line-height: 2.3rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2.2rem;
  }

  @media (min-width: $mdBreakpoint) {
    font-size: 2.4rem;
  }
}

.ContentBox {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.4rem;
  text-transform: uppercase;
}

.ContentBoxSmallLight {
  composes: ContentBox;
  color: $greyB;
}

.BoxTitle {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 2.2rem;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    font-size: 2.3rem;
    letter-spacing: 3px;
    line-height: 3.5rem;
  }
}

.BoxTitleSmall {
  composes: BoxTitle;

  @media (min-width: $smBreakpoint) {
    font-size: 1.8rem;
    letter-spacing: 0.8px;
    line-height: 2.7rem;
  }
}

.ChannelTitle {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.4rem;

  @media (min-width: $smBreakpoint) {
    line-height: 3.8rem;
  }

  @media (min-width: $mdBreakpoint) {
    font-size: 4rem;
    line-height: 4.6rem;
  }
}

.ChannelTitleWhite {
  composes: ChannelTitle;
  color: $white;
}

.ChapterTitle {
  color: $blackB;
  font-family: $fontGeorgia;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.9rem;

  @media (min-width: $mdBreakpoint) {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}

.ChapterTitleInactive {
  composes: ChapterTitle;
  color: $greyA;
}

.FooterParagraphWhite {
  color: $white;
  font-family: $fontRoboto;
  font-size: 1.7rem;
  letter-spacing: -0.2px;
  line-height: 2rem;
}


.KeywordsH1 {
  color: $blackB;
  font-family: $fontGeorgia;
  font-size: 3rem;
  letter-spacing: -0.4px;
  line-height: 3.5rem;

  @media (min-width: $smBreakpoint) {
    font-size: 4.5rem;
    line-height: 5rem;
  }
}

.KeywordsH2 {
  color: $blackB;
  font-family: $fontGeorgia;
  font-size: 2.8rem;
  letter-spacing: -0.4px;
  line-height: 3.3rem;

  @media (min-width: $smBreakpoint) {
    font-size: 4rem;
    line-height: 4.5rem;
  }
}

.PersonH1 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 2.7rem;
  font-weight: 700;
  line-height: 3.4rem;

  @media (min-width: $smBreakpoint) {
    font-size: 5rem;
    line-height: 6rem;
  }
}

.PersonH2Blue {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 2.7rem;

  @media (min-width: $smBreakpoint) {
    font-size: 3rem;
    letter-spacing: -0.4px;
    line-height: 4rem;
  }
}

.PersonH3Blue {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.PersonQuote2 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 4.4rem;
  line-height: 4.9rem;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    font-size: 6.5rem;
    line-height: 7rem;
  }
}

.PersonQuote {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 2.4rem;
  line-height: 3.5rem;
  text-transform: uppercase;
}

.RubrikenNavigation {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.7rem;
  line-height: 4.3rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2rem;
    line-height: 5rem;
  }
}

.RubrikenNavigationH1 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.7rem;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 2.2rem;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    font-size: 2rem;
    line-height: 5rem;
  }
}

/* not in styleguide */
.Metadata {
  color: $greyA;
  font-family: $fontRoboto;
  font-size: 1.1rem;
  line-height: 2.1rem;
}

.Meta1 {
  color: $greyA;
  font-family: $fontRoboto;
  font-size: 1.2rem;
  letter-spacing: 0.3px;
  line-height: 2.1rem;
}

.Meta3 {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.4rem;
  line-height: 1.9rem;
}

.Meta3White {
  composes: Meta3;
  color: $white;
}

.Meta3Light {
  composes: Meta3;
  color: $greyB;
}

.Meta3Bold {
  composes: Meta3White;
  font-family: $fontRoboto;
  font-weight: 700;
}

.Meta3Black {
  composes: Meta3;
}

.Meta4 {
  color: $white;
  font-family: $fontRoboto;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
}

.Claim {
  color: $greyA;
  font-family: $fontRoboto;
  font-size: 1.3rem;
  line-height: 1.7rem;

  @media (min-width: $xlBreakpoint) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.ClaimGrey {
  composes: Claim;
  color: $greyA;
}

.ClaimWhite {
  composes: Claim;
  color: $white;
}

.Header {
  color: $white;
  font-family: $fontRoboto;
  font-size: 1.5rem;
  line-height: 1.8rem
}

.HeaderBlack {
  composes: Header;
  color: $blackB;
}

.HeaderSmall {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.1rem;
  line-height: 1.4rem
}

.CategoryNavigationH1 {
  /* rubriken_navigation_h1 */
  color: $blackA;
  font-family: $fontRoboto;
  font-size: 1.7rem;
  font-weight: 700;
  letter-spacing: 0.57px;
  line-height: 2.2rem;
}

.CategoryNavigationH2 {
  /* rubriken_navigation_h2 */
  color: $primaryA;
  font-family: $fontRoboto;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 4.3rem;
}

.CategoryParagraph {
  /* rubriken_navigation_paragraph */
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.7rem;
  line-height: 4.3rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2rem;
    line-height: 5rem;
  }

  @media (min-width: $mdBreakpoint) {
    line-height: 4rem;
  }
}

.CategoryParagraphWhite {
  /* rubriken_navigation_paragraph */
  composes: CategoryParagraph;
  color: $white;
}

.ShortTitle {
  /* spitzmarke */
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.1rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.5rem;
    letter-spacing: 1.5px;
    line-height: 2rem;
  }
}

.FormLabelBig {
  color: $blackA;
  font-family: $fontRoboto;
  font-size: 1.6rem;
  line-height: 2.2rem;
}

.FormLabelBigGrey {
  composes: FormLabelBig;
  color: $greyA;
}

.FormLabelSmall {
  color: $blackA;
  font-family: $fontRoboto;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.FormLabelSmallGrey {
  composes: FormLabelSmall;
  color: $greyA;
}

.FormLabelSmallRed {
  composes: FormLabelSmall;
  color: $primaryA;
}

/* not in styleguide */
.SelectFieldParagraph {
  composes: PersonQuote;
  color: $blackB;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.4rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

/* not in styleguide */
.DescriptionBig {
  color: $greyB;
  font-family: $fontRoboto;
  font-size: 1.5rem;
  line-height: 2.4rem;

  @media (min-width: $smBreakpoint) {
    font-size: 2rem;
    line-height: 4rem;
  }
}

.ShortTitleA {
  color: $greyA;
  font-family: $fontRoboto;
  font-size: 1.3rem;
  letter-spacing: 1px;
  line-height: 1.6rem;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    line-height: 2rem;
  }
}

.ShortTitleBGreyA {
  composes: ShortTitleA;
  color: $greyA;

  @media (min-width: $smBreakpoint) {
    font-size: 1.3rem;
    letter-spacing: 1px;
    line-height: 1.6rem;
  }
}

.ShortTitleBWhite{
  composes: ShortTitleBGreyA;
  color: $white;
}

.ShortTitleC {
  composes: ShortTitleA;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 1.2rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.3rem;
    letter-spacing: 1px;
    line-height: 1.6rem;
  }
}

.ShortTitleD {
  composes: ShortTitleC;

  @media (min-width: $smBreakpoint) {
    font-size: 1rem;
    letter-spacing: 0.5px;
    line-height: 1.2rem;
  }
}

.ShortTitleBAdvertorial {
  composes: ShortTitleBGreyA;
  background-color: $decoB;
  border-radius: 4px; 
  padding: 4px 8px;
  width: fit-content;
}

.ShortTitleBNativeArticle {
  composes: ShortTitleBAdvertorial;
  background-color: $decoA;
}

.ShortTitleDAdvertorial {
  composes: ShortTitleD;
  background-color: $decoB;
  border-radius: 4px; 
  padding: 2px 4px;
  width: fit-content;
}

.ShortTitleDNativeArticle {
  composes: ShortTitleDAdvertorial;
  background-color: $decoA;
}

.InfoA {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.3rem;
}

.InfoABlack {
  composes: InfoA;
  font-family: $fontRoboto;
  font-weight: 700;
}

.InfoB {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.1rem;
}

.InfoBBlack {
  composes: InfoB;
  font-family: $fontRoboto;
  font-weight: 700;
}

.InfoC {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.9rem;
}

.InfoCBlack {
  composes: InfoC;
  font-family: $fontRoboto;
  font-weight: 700;
}

.InfoD {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
}

.InfoDBlack {
  composes: InfoD;
  font-family: $fontRoboto;
  font-weight: 700;
}

.InfoE {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
}

.InfoEBlack {
  composes: InfoE;
  font-family: $fontRoboto;
  font-weight: 700;
}

.TeaserTitle {
  color: $blackA;
  font-family: $fontRoboto;
  font-weight: bold;
}

.TeaserTitleH1 {
  composes: TeaserTitle;
  font-size: 2.5rem;
  line-height: 2.9rem;

  @media (min-width: $smBreakpoint) {
    font-size: 4rem;
    line-height: 4.8rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 5rem;
    line-height: 5.5rem;
  }
}

.TableContent {
  color: $blackA;
  font-family: $fontGeorgia;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.6rem;
}

.Lead {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.6rem;
  line-height: 2.5rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}

.Publishingdate {
  color: $greyA;
  font-family: $fontRoboto;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.7rem;
}

.ButtonPrimaryBig {
  @mixin ButtonPrimaryBig;
}

.ButtonPrimarySmall {
  @mixin ButtonPrimarySmall;
}

.ButtonSecondaryBig {
  @mixin ButtonSecondaryBig;
}

.ButtonSecondarySmall {
  @mixin ButtonSecondarySmall;
}

.ButtonTertiaryBig {
  @mixin ButtonTertiaryBig;
}

.ButtonTertiarySmall {
  @mixin ButtonTertiarySmall;
}

.FormLabelABlack {
  @mixin FormLabelABlack;
}

.FormLabelATextSecondary {
  @mixin FormLabelATextSecondary;
}

.FormLabelBBlack {
  @mixin FormLabelBBlack;
}

.FormLabelBError {
  @mixin FormLabelBError;
}

.FormLabelBTextSecondary {
  @mixin FormLabelBTextSecondary;
}

.Info5 {
  @mixin Info5;
}

.TeaserLeadBlack {
  color: $blackA;
  font-family: $fontRoboto;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.2rem;
}
