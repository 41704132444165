.Wrapper {
  border-bottom: 1px solid $greyA;
  border-top: 1px solid $greyA;
  padding: 16px 0;
}

.ImageContentWrapper {
  margin-bottom: $margin16;

  @media (min-width: $smBreakpoint) {
    display: flex;
  }

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 0;
  }

  &::after {
    clear: both;
    content: '';
    display:block;
  }
}

.ImageWrapper {
  float: left;
  margin-right: $margin16;
  position: relative;
  width: 92px;

  @media (min-width: $smBreakpoint) {
    float: none;
    margin-right: $margin32;
    width: 127px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-right: $margin40;
  }
}

.ImageWrapperSpecialOffer {
  @media (min-width: $smBreakpoint) {
    margin-right: $margin72;
  }
}

.Image {
  box-shadow: 0 2px 2px 0 $blackA;
  display: block;
  position: relative;
}

.ImageSpecialOffer {
  margin-bottom: $margin8;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 0;
  }
}

.SpecialOfferWrapper {
  background-color: $blackB;
  border-radius: 50%;
  bottom: -8px;
  height: 81px;
  position:absolute;
  right: -11px;
  transform: rotate(10deg);
  width: 81px;
  @media (min-width: $smBreakpoint) {
    bottom: initial;
    height: 106px;
    right: -56px;
    top: 56px;
    width: 106px;
  }
}

.SpecialOfferText {
  composes: Lead2White from 'typography.legacy.css';
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 0 4px;
  text-align: center;
  width: 100%;
  word-break: break-word;
}

.ContentWrapper {
  float: left;
  width: calc(100% - 108px);

  @media (min-width: $smBreakpoint) {
    float: none;
    width: calc(100% - 159px);
  }

  @media (min-width: $mdBreakpoint) {
    width: calc(100% - 167px);
  }
}

.ContentWrapperSpecialOffer {
  @media (min-width: $smBreakpoint) {
    width: calc(100% - 209px);
  }
}

.IssuePublishedText {
  composes: Lead2Grey from 'typography.legacy.css';
  margin-bottom: $margin8;
  overflow-wrap: break-word;
  word-break: break-word;
}

.IssueLink {
  composes: Lead2Grey from 'typography.legacy.css';
  text-decoration: underline;
}

.MagazineText {
  composes: H3 from 'typography.legacy.css';
  overflow-wrap: break-word;
  word-break: break-word;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin32;
  }
}

.CTAWrapper {
  display: block;

  @media (min-width: $smBreakpoint) {
    display: none;
  }
}

.CTAWrapperDesktop {
  display: none;

  @media (min-width: $smBreakpoint) {
    display: block;
  }
}

.SkeletonTitle {
  background-color: $greyC;
  height: 46px;
  margin-bottom: 16px;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 32px;
  }
}

.SkeletonIssuePublishedText {
  background-color: $greyC;
  height: 20px;
  margin-bottom: 8px;
  max-width: 150px;
  width: 100%;
}

.SkeletonButton {
  background-color: $greyC;
  height: 40px;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    height: 60px;
    width: 200px
  }
}
