.Header {
  font-family: $fontRoboto;
  font-size: 1.9rem;
  font-stretch: normal;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 2.5rem;
  text-transform: uppercase;

  @media (min-width: $xsBreakpointTo) {
    font-size: 2.3rem;
    letter-spacing: 2.9px;
    line-height: 3rem;
  }

  @media (min-width: $smBreakpointTo) {
    line-height: 3.5rem;
  }
}

.InnerTop {
  @mixin backgroundPatternDefault $white;
  height: 160px;
  margin-bottom: calc((160px - $defaultPadding) * -1);
  margin-top: 30px;
  width: 100%;

  @media (width >= smBreakpointTo) {
    height: 181px;
    margin-bottom: calc((181px - $defaultPadding) * -1);
  }

  @media (min-width: $xsBreakpointTo) {
    height: 151px;
    margin-bottom: calc((151px - $defaultPadding) * -1);
    margin-top: $margin40;
  }

  @media (min-width: $smBreakpointTo) {
    height: 201px;
    margin-bottom: calc((201px - $defaultPadding) * -1);
  }

  @media print {
    display: none;
  }
}

.Title {
  color: $blackB;
  display: inline-block;
  font-family: $fontRoboto;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 2.2rem;
  margin-top: 10px;
  text-transform: uppercase;

  @media (min-width: $xsBreakpointTo) {
    line-height: 2rem;
    margin-top: 20px;
  }

  @media (min-width: $smBreakpointTo) {
    line-height: 3.5rem;
  }
}

.TeaserHeaderWrapper {
  break-inside: avoid;
  margin-bottom: 22px;

  @media (min-width: $xsBreakpointTo) {
    margin-bottom: 26px;
  }

  @media (min-width: $smBreakpointTo) {
    margin-bottom: 18px;
  }
}
