.Background {
  background-color: $blackB;
}

.Wrapper {
  padding-bottom: 0;
  padding-top: 15px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    padding: 20px 0;
  }

  @media (min-width: $mdBreakpoint) {
    padding: 36px 0;
  }
}

.InnerWrapper {
  overflow-wrap: break-word;
  position: relative;
  word-wrap: break-word;
}

.HeaderWrapper {
  color: $blackB;
  overflow: hidden;
}

.HeaderText {
  composes: H2White from 'typography.legacy.css';
  margin-bottom: $margin8;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 15px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 21px;
  }
}

.LeadText {
  composes: InfoParagraphWhite from 'typography.legacy.css';
  margin-bottom: 18px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 27px;
    min-height: 78px;
  }
}

.HiddenTeaserImage {
  composes: HiddenMdDown from '@grid.legacy.css';
}

.TeaserImageWrapper {
  bottom: 0;
  line-height: 0;
  position: absolute;
  right: 0;
}

.TeaserImage {
  @media (min-width: $mdBreakpoint) {
    height: auto;
    width: 274px;
  }

  @media (min-width: $xlBreakpoint) {
    width: 286px;
  }
}
