.AlertsProfileWrapper {
  margin-bottom: $margin64;
  margin-top: $margin24;

  @media (min-width: $smBreakpoint) {
    margin-top: 27px;
  }

  @media (min-width: $mdBreakpoint) {
    margin-top: 29px;
  }
}

.LoginWrapper {
  display: flex;
}

.Title {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.9rem;
  margin-bottom: 12px;
  text-align-last: left;

  @media (min-width: $smBreakpoint) {
    font-size: 5.5rem;
    line-height: 5.9rem;
    margin-bottom: $margin16;
  }

  @media (min-width: $xlBreakpoint) {
    margin-bottom: $margin24;
  }
}

.Description {
  color: $blackB;
  font-family: $fontRoboto;
  font-size: 1.5rem;
  line-height: 2.4rem;
  margin-bottom: $margin24;

  @media (min-width: $smBreakpoint) {
    font-size: 1.9rem;
    line-height: 2.8rem;
    margin-bottom: $margin40;
  }
}
